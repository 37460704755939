<template>
  <div>
    <v-row class="">
      <v-col
        v-for="storeItem in items"
        :key="storeItem.item.id"
        class="pa-1"
        cols="6"
        md="4"
      >
        <StoreItemBox :store-item="storeItem" :currency="currency">
          <StoreItemDialog
            v-if="
              !storeItem.item.is_manual ||
              (storeItem.item.is_manual && !storeItem.has_menus)
            "
            :store-item="storeItem.item"
            :price="storeItem.price"
            :status="storeItem.status"
            :options="storeItem.options"
            :currency="currency"
          />
          <StoreItemExternalDialog
            v-else
            :store-item="storeItem.item"
            :price="storeItem.price"
            :status="storeItem.status"
            :options="storeItem.options"
          />
        </StoreItemBox>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import StoreItemBox from '@/components/store/store-item-box'
import { HOTJAR_EVENTS } from '@/lib/analytics/hotjar'
import StoreItemExternalDialog from '@/components/store/store-item-external-dialog'
import StoreItemDialog from '@/components/store/store-item-dialog'
export default {
  name: 'StoreItemsList',
  components: {
    StoreItemDialog,
    StoreItemExternalDialog,
    StoreItemBox,
  },
  props: ['items', 'currency'],
  mounted() {
    this.$store.dispatch('logAction', {
      eventType: HOTJAR_EVENTS.STORE_PAGE_OPENED,
    })
  },
}
</script>

<style scoped></style>
