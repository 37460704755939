<template>
  <v-layout column>
    <v-form
      ref="form"
      v-model="valid"
      class="pa-0 flex checkout-form"
      lazy-validation
    >
      <v-layout column justify-space-between>
        <div class="text-h5 font-weight-medium black--text mb-4">
          Personal Details
        </div>
        <v-flex class="stripe-form">
          <v-text-field
            v-model="fullname"
            label="Full Name"
            placeholder="Johnny depp"
            outlined
            dense
            :rules="nameRules"
            required
          />
          <v-text-field
            v-model="email"
            label="Email"
            placeholder="John@deer.com"
            outlined
            dense
            :rules="emailRules"
            required
          />
          <v-text-field
            v-model="phone"
            label="Phone"
            outlined
            dense
            :rules="phoneRules"
            placeholder="(123) 456-7890"
            required
          />
          <v-text-field
            v-model="zipCode"
            :label="$t('Zip code')"
            :rules="zipCodeRules"
            outlined
            dense
            placeholder="32505"
            required
          />
          <div class="text-h5 font-weight-medium black--text mb-4">
            Card Details
          </div>
          <v-row class="d-flex mb-4">
            <v-col
              class="d-flex pt-0 flex-column stripe-field-wrapper"
              cols="12"
            >
              <div id="example3-card-number" ref="card" class="field flex">
                <!-- A Stripe Element will be inserted here. -->
              </div>
              <div
                v-if="errors.cardNumber"
                class="text-caption error--text ml-2"
              >
                {{ errors.cardNumber }}
              </div>
            </v-col>
            <v-col cols="6" class="d-flex flex-column stripe-field-wrapper">
              <div id="example3-card-expiry" ref="exp" class="field flex">
                <!-- A Stripe Element will be inserted here. -->
              </div>
              <div
                v-if="errors.cardExpiry"
                class="text-caption error--text ml-2"
              >
                {{ errors.cardExpiry }}
              </div>
            </v-col>
            <v-col cols="6" class="d-flex flex-column stripe-field-wrapper">
              <div id="example3-card-cvc" ref="cvc" class="field flex">
                <!-- A Stripe Element will be inserted here. -->
              </div>
              <div v-if="errors.cardCvc" class="text-caption error--text ml-2">
                {{ errors.cardCvc }}
              </div>
            </v-col>
          </v-row>
          <v-divider
            :class="[
              'form-divider',
              'mt-2',
              { 'card-field-style': cardErrors, 'card-focus': cardFocus },
            ]"
          />
          <div class="text-caption error--text">
            {{ cardErrors }}
          </div>
          <slot />
          <v-row
            class="justify-md-space-between flex-column-reverse mt-6 px-3 flex-md-row align-md-center"
          >
            <div
              class="d-flex justify-md-start justify-sm-space-between align-center py-md-0 py-2"
            >
              <a
                class="stripe-badge mr-2"
                target="_blank"
                href="https://stripe.com/ssa"
              >
                <img width="100" src="./powered_by_stripe.png" alt="" />
              </a>
              <span class="d-flex">
                <span
                  ><a
                    target="_blank"
                    class="stripe-link"
                    href="https://stripe.com/legal"
                    >Terms</a
                  >
                </span>
                <v-divider class="mx-2" vertical />
                <span
                  ><a
                    target="_blank"
                    class="stripe-link"
                    href="https://stripe.com/privacy"
                    >Privacy</a
                  >
                </span>
              </span>
            </div>
            <v-btn
              type="submit"
              :loading="paymentProcessing"
              color="primary"
              elevation="0"
              :block="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
              large
              @click.prevent="purchase"
            >
              {{ buttonText }}
            </v-btn>
          </v-row>
        </v-flex>
      </v-layout>
    </v-form>
  </v-layout>
</template>

<script>
/* global Stripe */
import formRules from '../mixins/form-rules-mixin'
const elementStyles = {
  base: {
    color: '#000000',
    fontWeight: 300,
    fontFamily: 'Montserrat',
    fontSize: '16px',

    ':focus': {
      color: '#000000',
    },

    '::placeholder': {
      color: 'rgba(0, 0, 0, .6)',
    },

    ':focus::placeholder': {
      color: '#CFD7DF',
    },
  },
  invalid: {
    color: '#A01622',
    '::placeholder': {
      color: '#FFCCA5',
    },
  },
}

const elementClasses = {
  focus: 'focus',
  empty: 'empty',
  invalid: 'invalid',
}
const elementsOptions = {
  fonts: [
    {
      cssSrc:
        'https://fonts.googleapis.com/css?family=Montserrat:300,400,500,700',
    },
  ],
}

export default {
  name: 'StripeElement',
  mixins: [formRules],
  props: ['clientSecret', 'buttonText', 'publicKey', 'reservation'],
  data() {
    return {
      stripe: undefined,
      card: undefined,
      cvc: undefined,
      exp: undefined,
      errors: {
        cardNumber: '',
        cardExpiry: '',
        cardCvc: '',
      },
      cardErrors: '',
      valid: false,
      fullname: '',
      phone: '',
      zipCode: '',
      email: '',
      cardFocus: false,
      paymentProcessing: false,
    }
  },
  mounted: async function () {
    this.stripe = Stripe(this.publicKey)
    const stripeElements = this.stripe.elements(elementsOptions)
    if (this.guest) {
      this.fullname = this.guest.full_name || ''
      this.phone = this.guest.primary_phone || this.guest.phones[0] || ''
      this.email = this.guest.primary_email || this.guest.emails[0] || ''
    }
    this.card = stripeElements.create('cardNumber', {
      showIcon: true,
      hidePostalCode: true,
      style: elementStyles,
      classes: elementClasses,
      placeholder: 'Card Number',
    })
    this.cvc = stripeElements.create('cardCvc', {
      style: elementStyles,
      classes: elementClasses,
    })
    this.exp = stripeElements.create('cardExpiry', {
      style: elementStyles,
      classes: elementClasses,
    })
    this.card.mount(this.$refs.card)
    this.exp.mount(this.$refs.exp)
    this.cvc.mount(this.$refs.cvc)
    this.card.on('change', e => {
      if (e.error) {
        this.errors.cardNumber = e.error.message
      } else {
        this.errors.cardNumber = ''
      }
    })
    this.exp.on('change', e => {
      if (e.error) {
        this.errors.cardExpiry = e.error.message
      } else {
        this.errors.cardExpiry = ''
      }
    })
    this.cvc.on('change', e => {
      if (e.error) {
        this.errors.cardCvc = e.error.message
      } else {
        this.errors.cardCvc = ''
      }
    })
    this.card.on('focus', () => {
      this.cardFocus = true
    })
  },
  computed: {
    guest() {
      return this.reservation && this.reservation.guest
    },
  },
  methods: {
    purchase() {
      const isValid = this.$refs.form.validate()

      if (isValid) {
        this.$emit('payment-processing')
        this.stripe
          .confirmCardPayment(this.clientSecret, {
            payment_method: {
              card: this.card,
            },
            receipt_email: this.email,
          })
          .then(({ paymentIntent, error }) => {
            if (error) {
              this.cardErrors = error.message
              this.$emit('payment-failed')
              return
            }
            this.cardErrors = ''
            this.$emit('payment-success', {
              intent: paymentIntent,
              user: { email: this.email },
            })
          })
          .catch(() => {
            this.$emit('payment-failed')
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
label {
  position: relative;
  color: #6a7c94;
  font-weight: 400;
  height: 32px;
  line-height: 32px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
}

label > span {
  width: 115px;
}

.form-divider {
  background: rgb(118, 118, 118);
}
.card-focus:not(.card-field-style) {
  background: #7ebac0;
  height: 2px;
}

.stripe-link {
  text-decoration: none;
}
.stripe-badge {
  line-height: 0;
}

.stripe-form .field {
  padding: 10px 12px 10px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.38);
  width: 100%;
  margin-top: 6px;
  + div {
    position: absolute;
    left: 15px;
    bottom: -10px;
  }
}

.stripe-form .field.focus,
.stripe-form .field:focus {
  border-color: var(--v-primary-base);
  border-width: 2px;
}

.stripe-form .field.invalid {
  border-color: var(--v-error-base);
  color: var(--v-error-base);
  border-width: 2px;
}

.stripe-form input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  border-style: none;
}

.stripe-field-wrapper {
  position: relative;
}
</style>
