<template>
  <v-row
    class="grey lighten-5 text-center pa-3 d-flex justify-center flex-wrap flex-sm-nowrap"
  >
    <v-col class="flex">
      <span v-show="!value" class="my-3"
        ><v-icon small>fas fa-images</v-icon></span
      >
      <div class="font-weight-medium text-subtitle-2">
        {{ title }}
      </div>

      <v-dialog v-model="showCameraDialog" max-width="600px">
        <v-card>
          <v-card-title>{{ $t('Take a Photo') }}</v-card-title>
          <v-card-text>
            <photo-camera
              @capture="onCameraCapture"
              @close="showCameraDialog = false"
            />
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-file-input
        ref="file"
        v-model="files"
        type="file"
        style="display: none"
        accept="image/png,image/jpeg,image/jpg,image/webp"
        @change="readURL"
      />
      <v-sheet
        outlined
        height="160"
        width="250"
        class="mx-auto ma-3 p-relative dashed-border"
      >
        <v-overlay
          v-if="filesLoading"
          :opacity="0.1"
          absolute
          class="flex-center"
        >
          <v-progress-circular color="primary" indeterminate size="50" />
        </v-overlay>
        <v-img
          contain
          width="100%"
          height="100%"
          class="rounded"
          :src="value"
        />
      </v-sheet>

      <div class="d-flex justify-center">
        <v-btn
          v-if="showCamera"
          outlined
          color="primary"
          class="my-3 mr-2"
          small
          :disabled="filesLoading"
          @click="showCameraDialog = true"
        >
          <v-icon left small>fas fa-camera</v-icon>
          {{ $t('Take Photo') }}
        </v-btn>

        <v-btn
          v-if="showUpload"
          outlined
          color="black"
          class="my-3"
          small
          :disabled="filesLoading"
          @click="pickFile"
        >
          <v-icon left small>fas fa-upload</v-icon>
          {{ $t(`Upload ${value ? 'another' : ''} file`) }}
        </v-btn>
      </div>
    </v-col>
    <slot name="instructions" />
  </v-row>
</template>

<script>
import axios from 'axios'
import PhotoCamera from '@/components/common/photo-camera.vue'

export default {
  name: 'ProfileUpload',
  components: {
    PhotoCamera,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      required: true,
    },
    uploadOption: {
      type: String,
      default: 'upload',
      validator: function (value) {
        return ['both', 'camera', 'upload'].includes(value)
      },
    },
  },
  computed: {
    showCamera() {
      return ['both', 'camera'].includes(this.uploadOption)
    },
    showUpload() {
      return ['both', 'upload'].includes(this.uploadOption)
    },
  },
  data() {
    return {
      files: [],
      picHovered: false,
      filesLoading: false,
      imageProgress: 0,
      showCameraDialog: false,
    }
  },
  methods: {
    hoverPic() {
      this.picHovered = true
    },
    outPic() {
      this.picHovered = false
    },
    readURL() {
      this.filesLoading = true
      const formData = new FormData()
      formData.append('upload_preset', 'ie2mgrcv')
      formData.append('file', this.files)
      return axios
        .post('https://api.cloudinary.com/v1_1/do4tedxg6/upload', formData, {
          withCredentials: false,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: progressEvent => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / this.files.size
            )
            this.imageProgress = progress > 100 ? 100 : progress
          },
        })
        .then(result => {
          const urls = result.data.secure_url
          this.$emit('update:value', urls)
          this.$emit('on-upload', urls)
        })
        .catch(err => (this.error = err))
        .then(() => {
          this.filesLoading = false
        })
    },
    pickFile() {
      this.$refs.file.$refs.input.click()
    },
    async onCameraCapture(imageData) {
      this.filesLoading = true
      this.showCameraDialog = false

      try {
        const formData = new FormData()
        formData.append('upload_preset', 'ie2mgrcv')

        const response = await fetch(imageData)
        const blob = await response.blob()
        formData.append('file', blob)

        const result = await axios.post(
          'https://api.cloudinary.com/v1_1/do4tedxg6/upload',
          formData,
          {
            withCredentials: false,
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        )

        const urls = result.data.secure_url
        this.$emit('update:value', urls)
        this.$emit('on-upload', urls)
      } catch (err) {
        this.error = err
      } finally {
        this.filesLoading = false
      }
    },
  },
}
</script>

<style scoped>
.upload-box {
  border: 1px solid var(--v-secondary-lighten1);
  position: relative;
  cursor: pointer;
}
.dashed-border {
  border: 1px dashed black !important;
  background-color: transparent !important;
  border-radius: 10px;
}
</style>
