<template>
  <div>
    <v-btn
      v-if="!hideButton"
      elevation="0"
      large
      color="primary"
      @click="handleDialogOpen"
    >
      {{ $t('Submit') }}
    </v-btn>
    <v-dialog v-model="dialog" max-width="800" @input="handleDialogClose">
      <v-card>
        <v-card-title
          class="text-h5 justify-center py-10 font-weight-bold dialog-title"
          >{{ $t('Digital registration completed!') }}</v-card-title
        >
        <v-card-text class="d-flex justify-center">
          <v-icon class="mb-10" size="100">$check</v-icon>
        </v-card-text>
        <div class="pa-5 text-center">
          <v-btn color="success lighten-1" depressed rounded @click="onClose"
            >OK</v-btn
          >
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CommonFunctions from 'components/mixins/common-functions.js'

export default {
  mixins: [CommonFunctions],
  props: ['nextStep', 'reservation', 'hideButton'],
  data() {
    return {
      dialog: false,
    }
  },
  methods: {
    goToReservation() {
      this.$router.push({
        name: 'reservation-info',
        params: { id: this.reservation.confirmation_code },
      })
    },
    handleDialogOpen() {
      const res = this.nextStep(false)
      if (res) this.dialog = true
    },
    handleDialogClose(value) {
      if (!value) this.onClose()
    },
    onClose() {
      this.dialog = false
      this.nextStep(true)
    },
  },
}
</script>
<style scoped>
.dialog-title {
  word-break: break-word;
  text-align: center;
  line-height: 2.5rem;
}
</style>
