<template>
  <div class="camera-container">
    <video
      v-show="!capturedImage"
      ref="video"
      class="camera-preview"
      autoplay
      playsinline
    ></video>
    <img v-if="capturedImage" :src="capturedImage" class="captured-image" />
    <canvas ref="canvas" style="display: none"></canvas>
    <div v-if="cameraError" class="camera-error">
      Please allow camera access to use this feature
    </div>

    <div class="camera-controls">
      <v-btn
        v-if="!capturedImage && isMobile"
        color="primary"
        @click="switchCamera"
        icon
        class="mr-2"
      >
        <v-icon>fas fa-sync</v-icon>
      </v-btn>
      <v-btn v-if="!capturedImage" color="primary" @click="capture" icon>
        <v-icon>fas fa-camera</v-icon>
      </v-btn>
      <template v-else>
        <v-btn color="error" @click="retake" icon>
          <v-icon>fas fa-redo</v-icon>
        </v-btn>
        <v-btn color="success" @click="confirm" icon>
          <v-icon>fas fa-check</v-icon>
        </v-btn>
      </template>
    </div>
  </div>
</template>

<script>
import deviceMixin from '@/components/mixins/deviceMixin'

export default {
  name: 'PhotoCamera',
  mixins: [deviceMixin],
  data() {
    return {
      stream: null,
      capturedImage: null,
      facingMode: 'user',
      cameraError: false,
    }
  },
  mounted() {
    this.startCamera()
  },
  beforeDestroy() {
    this.stopCamera()
  },
  methods: {
    async startCamera() {
      try {
        if (this.stream) {
          this.stopCamera()
        }

        this.stream = await navigator.mediaDevices.getUserMedia({
          video: { facingMode: this.facingMode },
          audio: false,
        })
        this.$refs.video.srcObject = this.stream
        this.cameraError = false
      } catch (err) {
        console.error('Error accessing camera:', err)
        this.cameraError = true
      }
    },
    async switchCamera() {
      this.facingMode = this.facingMode === 'user' ? 'environment' : 'user'
      await this.startCamera()
    },
    stopCamera() {
      if (this.stream) {
        this.stream.getTracks().forEach(track => track.stop())
      }
    },
    capture() {
      const canvas = this.$refs.canvas
      const video = this.$refs.video
      canvas.width = video.videoWidth
      canvas.height = video.videoHeight
      canvas.getContext('2d').drawImage(video, 0, 0)
      this.capturedImage = canvas.toDataURL('image/jpeg')
    },
    retake() {
      this.capturedImage = null
    },
    confirm() {
      this.$emit('capture', this.capturedImage)
    },
  },
}
</script>

<style scoped>
.camera-container {
  position: relative;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}

.camera-preview,
.captured-image {
  width: 100%;
  max-height: 400px;
  object-fit: cover;
}

.camera-controls {
  margin-top: 16px;
  display: flex;
  justify-content: center;
  gap: 16px;
}

.camera-error {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
}
</style>
