<template>
  <v-stepper-content :step="step" class="pa-0 fill-height">
    <v-row class="justify-center pa-6">
      <v-col cols="12" class="text-center">
        <v-card
          class="grey--text text--darken-4 white thin-grey-border text-start"
          outlined
        >
          <div class="text-subtitle-1 font-weight-bold mb-3">
            Small Fee, Big Peace of Mind!
          </div>
          <p class="text-body-2 grey--text text--darken-2 mb-3">
            We want you to relax and enjoy your stay without stressing over
            little accidents—that's where our
            <b>Accidental Damage Waiver</b> comes in! For
            <b>a small, one-time fee,</b> you're covered for
            <b>up to {{ toCurrency(depositAmount, { currency }) }}</b> in
            accidental damage.
          </p>
          <ul class="mb-3 pl-0" style="list-style-type: none">
            <li class="d-flex align-center mb-2">
              <v-icon class="mr-2" small color="success">mdi-check</v-icon>
              <span><b>Spilled wine?</b> Covered.</span>
            </li>
            <li class="d-flex align-center mb-2">
              <v-icon class="mr-2" small color="success">mdi-check</v-icon>
              <span><b>Broken glass?</b> No worries.</span>
            </li>
            <li class="d-flex align-center mb-2">
              <v-icon class="mr-2" small color="success">mdi-check</v-icon>
              <span><b>Oops moments?</b> We've got you.</span>
            </li>
          </ul>
          <p
            class="text-body-2 grey--text text--darken-2 mb-3"
            v-if="!isMobile"
          >
            Just let us know about any accidents before checkout, and we'll take
            care of it—no surprise charges, no hassle.
          </p>
          <span></span>
          <ul class="mb-3 pl-0" style="list-style-type: none">
            <v-alert
              class="text--darken-2 font-weight-medium text-body-2"
              dense
              text
              icon="mdi-shield-home"
              type="info"
            >
              {{ $t('DamageProtectionOption') }}
            </v-alert>
          </ul>
        </v-card>
        <div class="mt-5 d-flex flex-column align-center">
          <template>
            <v-btn
              depressed
              class="font-weight-bold mb-3"
              width="300px"
              large
              color="info"
              @click="placeOrder"
            >
              {{ $t('PayWaiver') }}
            </v-btn>
            <v-btn
              depressed
              class="font-weight-bold"
              width="300px"
              large
              color="secondary"
              @click="skipPayment"
            >
              {{ $t('PayDepositLater') }}
            </v-btn>
          </template>
        </div>
      </v-col>
    </v-row>
  </v-stepper-content>
</template>

<script>
import { HOTJAR_EVENTS } from '@/lib/analytics/hotjar'
import { mapState, mapActions } from 'vuex'
import deviceMixin from 'components/mixins/deviceMixin'
import commonFunctions from 'components/mixins/common-functions'

export default {
  name: 'DamageWaiver',
  props: ['step'],
  mixins: [deviceMixin, commonFunctions],
  computed: {
    ...mapState({
      storeItems: state => state.cart.storeItems,
    }),
    damageWaiver() {
      return Object.values(this.storeItems).find(
        item => item.name === 'Damage Waiver'
      )
    },
    depositAmount() {
      return this.deposit?.amount ? this.deposit.amount / 100 : 500
    },
    currency() {
      return this.reservation?.listing?.get_currency
    },
    deposit() {
      return (
        this.reservation.payment_intents.find(
          intent =>
            intent.metadata.type === 'deposit' &&
            (intent.status === 'requires_source' ||
              intent.status === 'requires_payment_method')
        ) || {}
      )
    },
    reservation() {
      return (
        this.$store.getters.currentReservation || {
          listing: {},
          payment_intents: [],
        }
      )
    },
    damageWaiverIntent() {
      return this.reservation.payment_intents.find(
        intent =>
          this.damageWaiver?.id &&
          intent.store_item_purchase?.store_item_id === this.damageWaiver?.id &&
          (intent.status === 'requires_source' ||
            intent.status === 'requires_payment_method')
      )
    },
  },
  methods: {
    ...mapActions(['logAction']),
    nextStep() {
      this.$emit('step-forward')
    },
    skipPayment() {
      this.nextStep()
    },
    async placeOrder() {
      if (this.damageWaiver?.id || this.damageWaiverIntent?.payment_intent_id) {
        this.logAction({
          eventType: HOTJAR_EVENTS.COMPOSE_ORDER_ATTEMPT,
          storeItemId: this.damageWaiver.id,
        })

        if (this.damageWaiverIntent?.id) {
          this.$router.push({
            name: 'store-checkout',
            query: { ...this.$route.query },
            params: {
              id: this.damageWaiverIntent.payment_intent_id,
              isDamageProtection: true,
            },
          })
        } else {
          const purchase = await this.$store.dispatch('placeOrder', {
            reservationId: this.$route.params.id,
            itemId: this.damageWaiver.id,
            count: 1,
            options: {
              is_damage_protection: true,
            },
          })

          this.logAction({
            eventType: HOTJAR_EVENTS.COMPOSE_ORDER_SUCCESSFUL,
            storeItemId: this.damageWaiver.id,
          })

          this.$router.push({
            name: 'store-checkout',
            query: { ...this.$route.query },
            params: {
              id: purchase.payment_intent.payment_intent_id,
              isDamageProtection: true,
            },
          })
        }
      }
    },
  },
}
</script>

<style scoped></style>
