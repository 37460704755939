<template>
  <div class="d-flex flex flex-column map-wrapper">
    <GmapMap
      v-if="!isMobile"
      :center="{ lat: position.lat, lng: position.lng }"
      :zoom="16"
      :options="{ disableDefaultUI: true, styles }"
      map-type-id="terrain"
      style="min-height: 500px"
    >
      <GmapMarker :key="1" :position="position" />
    </GmapMap>
    <v-col
      ref="reservationInfo"
      class="d-flex reservation-info-wrapper pa-0"
      cols="12"
      sm="7"
      md="5"
      lg="4"
    >
      <v-card
        elevation="0"
        style="height: 100%"
        :class="[
          'overflow-auto',
          {
            'overflow-hidden': shouldPayAccommodation || shouldPayDeposit,
          },
        ]"
      >
        <v-overlay
          v-show="shouldPayAccommodation"
          color="white"
          light
          absolute
          :opacity="0.9"
        >
          <v-card
            elevation="0"
            max-width="550"
            min-width="300"
            class="d-flex transparent"
          >
            <v-row class="justify-center align-center flex-column pa-6">
              <v-icon color="warning" size="150" class="pa-2">
                fas fa-credit-card
              </v-icon>
              <div class="pb-6 text-center black--text text-subtitle-2">
                {{ $t('PleaseCompleteBookingPayment') }}
              </div>
              <div>
                <v-btn
                  :to="`/tourist/trips/${reservation.confirmation_code}/checkout/${accommodation.payment_intent_id}`"
                  link
                  color="warning"
                >
                  {{ $t('ProceedToCheckout') }}
                </v-btn>
              </div>
            </v-row>
          </v-card>
        </v-overlay>
        <v-overlay
          v-if="shouldPayDeposit || shouldPayDamageWaiver"
          absolute
          color="white"
          light
          :opacity="1"
        >
          <div>
            <v-alert
              class="ma-4 font-weight-medium text-body-2"
              outlined
              icon="mdi-lock-outline"
              type="error"
            >
              {{ $t('ReservationDetailsAvailability') }}
            </v-alert>
          </div>
          <v-row class="justify-center pa-6">
            <v-col cols="12" class="text-center">
              <div class="mt-5 d-flex flex-column align-center">
                <template v-if="shouldPayDamageWaiver">
                  <v-btn
                    depressed
                    class="font-weight-bold"
                    width="300px"
                    large
                    color="info"
                    @click="placeOrder"
                  >
                    {{ $t('PayWaiver') }}
                  </v-btn>
                  <div
                    class="px-4 font-weight-bold text-subtitle-1 black--text my-2"
                  >
                    {{ $t('or') }}
                  </div>
                </template>
                <v-btn
                  v-if="shouldPayDeposit"
                  :to="`/tourist/trips/${reservation.confirmation_code}/checkout/${deposit.payment_intent_id}`"
                  color="warning"
                  depressed
                  large
                  width="300px"
                  class="mb-3 font-weight-bold"
                >
                  {{ $t('PayDeposit') }}
                </v-btn>
              </div>
              <v-card
                v-if="shouldPayDamageWaiver"
                class="mt-10 pt-4 grey--text text--darken-4 white thin-grey-border text-start"
                outlined
              >
                <div class="text-subtitle-1 font-weight-bold mb-3">
                  {{ $t('DamageWaiverTitle') }}
                </div>
                <p class="text-body-2 grey--text text--darken-2 mb-3">
                  {{ $t('DamageWaiverDescriptionOption') }}
                </p>
                <p
                  class="text-body-2 mb-2 font-weight-medium grey--text text--darken-2"
                >
                  {{ $t('ProtectionOptionsTitle') }}
                </p>
                <span></span>
                <ul class="mb-3 pl-0" style="list-style-type: none">
                  <v-alert
                    class="text--darken-2 font-weight-medium text-body-2"
                    dense
                    text
                    icon="mdi-shield-home"
                    type="info"
                  >
                    {{ $t('DamageProtectionOption') }}
                  </v-alert>
                  <v-alert
                    class="text--darken-2 font-weight-medium text-body-2"
                    dense
                    text
                    icon="mdi-shield-check"
                    type="warning"
                  >
                    {{ $t('SecurityDepositOption') }}
                  </v-alert>
                </ul>
              </v-card>
            </v-col>
          </v-row>
        </v-overlay>
        <v-carousel
          v-if="first5Pics.length"
          :height="carouselHeight"
          hide-delimiter-background
          next-icon="chevron_right"
          prev-icon="chevron_left"
        >
          <v-carousel-item v-for="(slide, i) in first5Pics" :key="i">
            <v-img
              width="100%"
              height="100%"
              :src="slide.large || slide.original"
            />
          </v-carousel-item>
        </v-carousel>
        <v-img v-else :src="reservation.listing.container_picture"> </v-img>
        <v-overlay
          v-if="isTouristLoading"
          :opacity="0.1"
          absolute
          class="flex-center"
        >
          <v-progress-circular color="primary" indeterminate size="50" />
        </v-overlay>
        <div v-else class="d-flex flex flex-column overflow-y-auto pa-3">
          <div class="d-flex">
            <v-btn
              class="me-4"
              icon
              :href="googleMapsLink"
              target="_blank"
              color="primary"
            >
              <v-icon> fas fa-map-marker-alt </v-icon>
            </v-btn>
            <div>
              <div class="mt-0 text-subtitle-1 text-sm-h6">
                {{ title }}
              </div>
              <div class="text-body-2 secondary--text">
                {{ reservation.listing.city_name }}
              </div>
            </div>
          </div>
          <v-divider class="my-4" />
          <v-row class="px-3" no-gutters>
            <v-col cols-6 class="pl-3">
              <CheckDateBox
                :title="$t('Check-in')"
                :date="reservation.check_in"
                :timezone="listing.timezone"
                :time="touristsData.min_check_in_time_string"
                :planned-time="timesInfo.requested_check_in_time"
              />
            </v-col>
            <v-divider vertical />
            <v-col cols-6 class="pl-3">
              <CheckDateBox
                :title="$t('Checkout')"
                :date="reservation.check_out"
                :timezone="listing.timezone"
                :time="touristsData.max_check_out_time_string"
                :planned-time="timesInfo.requested_check_out_time"
              />
            </v-col>
          </v-row>
          <v-divider class="my-4" />
          <div class="px-3">
            <div class="text-h6 font-weight-medium my-2">
              {{ $t('Getting there') }}
            </div>
            <div class="text-subtitle-1 font-weight-medium">
              {{ $t('Address') }}
            </div>
            <div class="text-caption">
              <a :href="googleMapsLink" target="_blank" class="black--text">{{
                reservation.listing.address
              }}</a>
            </div>
            <v-divider class="my-4" />
            <div v-if="checkinInstructions">
              <v-expansion-panels flat>
                <v-expansion-panel>
                  <v-expansion-panel-header
                    class="ma-0 pa-0"
                    expand-icon="mdi-menu-down"
                  >
                    <div>
                      <v-icon class="mr-2" small> $map_directions </v-icon>
                      {{ $t('Arrival Instructions') }}
                    </div></v-expansion-panel-header
                  >
                  <v-expansion-panel-content>
                    <div
                      class="text-caption mt-2"
                      style="white-space: pre-line"
                      v-html="marked(checkinInstructions)"
                    ></div>
                    <gallery
                      :thumbnails="true"
                      :cols="4"
                      :images="checkinInstructionsMedia"
                  /></v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-divider class="my-4" />
            </div>
            <div v-if="checkoutInstructions && isCloseToCheckout">
              <v-expansion-panels flat>
                <v-expansion-panel>
                  <v-expansion-panel-header
                    class="ma-0 pa-0"
                    expand-icon="mdi-menu-down"
                  >
                    <div>
                      <v-icon class="mr-2 flip-horizontal" small>
                        $map_directions
                      </v-icon>
                      {{ $t('Departure Instructions') }}
                    </div></v-expansion-panel-header
                  >
                  <v-expansion-panel-content>
                    <div
                      class="text-caption mt-2"
                      style="white-space: pre-line"
                      v-html="marked(checkoutInstructions)"
                    ></div>
                    <gallery
                      :thumbnails="true"
                      :cols="4"
                      :images="checkoutInstructionsMedia"
                  /></v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-divider class="my-4" />
            </div>
          </div>
          <v-row class="no-gutters">
            <v-col cols="12">
              <div
                v-if="touristsData.error"
                class="text-caption font-weight-medium warning--text pb-1"
              >
                <v-icon x-small class="pr-1" color="warning">
                  fas fa-exclamation-triangle
                </v-icon>
                {{ $t(touristsData.error) }}
                {{ errorCheckinDate }}
                {{ touristsData.min_check_in_time_string }}
              </div>
            </v-col>
            <v-col>
              <BookingCodes
                :disabled="Boolean(touristsData.error)"
                :tourist-data="touristsData"
              />
            </v-col>
          </v-row>
          <v-divider class="my-4" />
          <v-row class="px-3" no-gutters>
            <v-col cols="12">
              <PurchasesReview
                v-if="purchasedItems.length"
                :items="purchasedItems"
              />
            </v-col>
            <v-col
              v-if="!isReservationPassed"
              class="d-flex justify-center flex-column text-center"
            >
              <div>
                <v-btn
                  v-if="storeEnabled && touristsData.has_store_items"
                  dark
                  small
                  class="white--text font-weight-bold ma-2"
                  depressed
                  rounded
                  :to="{
                    name: 'tourist-store',
                    id: reservation.confirmation_code,
                  }"
                  color="pink lighten-1"
                >
                  <v-icon small class="me-2">mdi-gift</v-icon>
                  {{ $t('BrowseVacationAddons') }}
                </v-btn>
              </div>
              <travel-guide-modal
                v-if="travelGuideUrl"
                :travel-guide-url="travelGuideUrl"
              />
            </v-col>
          </v-row>
          <v-divider class="my-4" />
          <div class="d-flex px-3">
            <pre-check-in-summary />
          </div>
        </div>
      </v-card>
    </v-col>
  </div>
</template>

<script>
import CheckDateBox from 'components/common/check-date-box'
import take from 'lodash/fp/take'
import BookingCodes from 'components/reservations/booking-codes'
import PreCheckInSummary from 'components/reservations/pre-check-in-summary'
import mapStyles from 'components/map-style'
import PurchasesReview from 'components/store/purchases-review'
import featureFlagsMixin from '@/components/mixins/featureFlagsMixin'
import CommonFunctions from '@/components/mixins/common-functions'
import moment from 'moment'
import deviceMixin from 'components/mixins/deviceMixin'
import Gallery from 'components/common/gallery.vue'
import TravelGuideModal from './travel-guide-modal.vue'
import { marked } from 'marked'
import { mapGetters, mapState, mapActions } from 'vuex'
import { HOTJAR_EVENTS } from '@/lib/analytics/hotjar'

const renderer = new marked.Renderer()
renderer.link = function (href, title, text) {
  return `<a href="${href}" title="${title}" target="_blank" rel="noopener noreferrer">${text}</a>`
}

export default {
  name: 'ReservationInfo',
  components: {
    PurchasesReview,
    PreCheckInSummary,
    BookingCodes,
    CheckDateBox,
    Gallery,
    TravelGuideModal,
  },
  mixins: [featureFlagsMixin, CommonFunctions, deviceMixin],
  data() {
    return {
      dialog: false,
      carouselHeight: 0,
    }
  },
  computed: {
    ...mapGetters(['isTouristLoading']),
    ...mapState({
      storeItems: state => state.cart.storeItems,
    }),
    styles() {
      return mapStyles
    },
    pre_check_in() {
      return this.reservation.pre_check_in || {}
    },
    isReservationPassed() {
      return moment(this.reservation.check_out).isBefore(moment())
    },
    user() {
      return this.$store.state.user.user
    },
    isCloseToCheckout() {
      return moment(this.reservation.check_out).isBefore(
        moment().add(12, 'days')
      )
    },
    checkinInstructions() {
      const { instructions_langs, checkin_instructions } = this.touristsData
      const { locale } = this.$i18n
      const current =
        instructions_langs &&
        instructions_langs[locale] &&
        instructions_langs[locale].check_in
      const english =
        instructions_langs &&
        instructions_langs.en &&
        instructions_langs.en.check_in
      return current || english || checkin_instructions
    },
    checkoutInstructions() {
      const { instructions_langs, checkout_instructions } = this.touristsData
      const { locale } = this.$i18n
      const current =
        instructions_langs &&
        instructions_langs[locale] &&
        instructions_langs[locale].check_out
      const english =
        instructions_langs &&
        instructions_langs.en &&
        instructions_langs.en.check_out
      return current || english || checkout_instructions
    },
    googleMapsLink() {
      const { lat, lng, address } = this.reservation.listing
      const hasCoordinates = lat && lng
      const coordinatesUrl = hasCoordinates
        ? `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`
        : null

      return (
        coordinatesUrl ||
        this.touristsData.custom_address_url ||
        `https://www.google.com/maps/dir/?api=1&destination=${address}`
      )
    },
    travelGuideUrl() {
      const checkinUrl =
        this.reservation.checkin_info &&
        this.reservation.checkin_info.travel_guide_url
      const listingUrl = this.touristsData && this.touristsData.travel_guide_url
      return listingUrl || checkinUrl
    },
    checkinInstructionsMedia() {
      return (this.touristsData.checkin_instructions_media || []).map(
        img => img.src || img
      )
    },
    checkoutInstructionsMedia() {
      return (this.touristsData.checkout_instructions_media || []).map(
        img => img.src || img
      )
    },
    touristsData() {
      return this.$store.getters.reservationInfo
    },
    errorCheckinDate() {
      if (!this.reservation.planned_arrival) return ''
      return moment(this.reservation.check_in)
        .tz(this.listing.timezone)
        .format('MM/DD/YYYY')
    },
    damageWaiverIntent() {
      return this.reservation.payment_intents.find(
        intent =>
          this.damageWaiver?.id &&
          intent.store_item_purchase?.store_item_id === this.damageWaiver?.id &&
          (intent.status === 'requires_source' ||
            intent.status === 'requires_payment_method')
      )
    },
    acceptDamageWaiver() {
      return (
        this.reservation.checkin_info &&
        this.reservation.checkin_info.accept_damage_waiver &&
        this.damageWaiver?.id
      )
    },
    acceptAccommodation() {
      return (
        this.reservation.checkin_info &&
        this.reservation.checkin_info.accept_portal_payments
      )
    },
    shouldPayAccommodation() {
      return (
        !this.pre_check_in.unlock_portal_access &&
        this.acceptAccommodation &&
        this.reservation.is_merchant &&
        !this.reservation.use_vcc &&
        this.reservation.balance_due > 0 &&
        this.reservation.payment_intents.find(
          intent =>
            intent.metadata.type === 'accommodation' &&
            intent.status === 'requires_source'
        )
      )
    },
    shouldPayDamageWaiver() {
      return (
        !this.pre_check_in.unlock_portal_access &&
        this.acceptDamageWaiver &&
        this.shouldPayDeposit &&
        this.touristsData.has_damage_waiver_to_pay
      )
    },
    reservation() {
      return (
        this.$store.getters.currentReservation || {
          listing: { pictures: [] },
          payment_intents: [],
        }
      )
    },
    listing() {
      return this.reservation.listing
    },
    position() {
      if (!this.touristsData) return null
      const url = this.touristsData.custom_address_url
      if (url && url.split('@')[1]) {
        const [lat, lng] = url.split('@')[1].split(',')
        return { lat: parseFloat(lat), lng: parseFloat(lng) }
      }
      const { lat, lng } = this.reservation.listing
      return { lat, lng }
    },
    purchasedItems() {
      return this.$store.getters.purchasedItems
    },
    deposit() {
      return (
        this.reservation.payment_intents.find(
          intent =>
            intent.metadata.type === 'deposit' &&
            (intent.status === 'requires_source' ||
              intent.status === 'requires_payment_method')
        ) || {}
      )
    },
    accommodation() {
      return (
        this.reservation.payment_intents.find(
          intent =>
            intent.metadata.type === 'accommodation' &&
            (intent.status === 'requires_source' ||
              intent.status === 'requires_payment_method')
        ) || {}
      )
    },
    damageWaiver() {
      return Object.values(this.storeItems).find(
        item => item.name === 'Damage Waiver'
      )
    },
    first5Pics() {
      return take(5, this.reservation.listing.container_pictures)
    },
    preCheckIn() {
      return this.reservation.pre_check_in || {}
    },
    title() {
      const { nickname, hotel_name } = this.reservation.listing
      const { checkin_info } = this.reservation
      const hide_hotel_units = checkin_info && checkin_info.hide_hotel_units
      const check_in = moment(this.reservation.check_in)
      const before_checkin_day = check_in.isBefore(moment().startOf('day'))
      return (hide_hotel_units && before_checkin_day && hotel_name) || nickname
    },
    timesInfo() {
      return this.touristsData.times_info || {}
    },
  },
  watch: {
    preCheckIn(preCheckIn) {
      if (preCheckIn) {
        this.validatePreCheckIn(preCheckIn.status)
      }
    },
  },
  created() {
    window.addEventListener('resize', this.resizeEvent)
  },
  mounted() {
    this.resizeEvent()
  },
  beforeCreate() {
    this.$store.dispatch('getReservations')
  },
  beforeMount() {
    if (this.reservation.pre_check_in) {
      this.validatePreCheckIn(this.reservation.pre_check_in.status)
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeEvent)
  },
  methods: {
    ...mapActions(['logAction']),
    marked(val) {
      return val ? marked(val.toString(), { renderer: renderer }) : val
    },
    validatePreCheckIn(status) {
      if (status !== 'Done') {
        this.$router.push({
          name: 'pre-check-in',
          params: {
            id: this.reservation.confirmation_code,
            message:
              'Please complete the pre-check-in process to access your reservation details.',
          },
        })
      }
    },
    goBack() {
      this.$router.back()
    },
    showDirections() {
      window.open(
        this.googleMapsLink,
        '_blank' // <- This is what makes it open in a new window.
      )
    },
    resizeEvent() {
      this.carouselHeight = this.$refs.reservationInfo.clientWidth * 0.65
    },
    async placeOrder() {
      if (this.damageWaiver?.id || this.damageWaiverIntent?.payment_intent_id) {
        this.logAction({
          eventType: HOTJAR_EVENTS.COMPOSE_ORDER_ATTEMPT,
          storeItemId: this.damageWaiver.id,
        })

        if (this.damageWaiverIntent?.payment_intent_id) {
          this.$router.push({
            name: 'store-checkout',
            query: { ...this.$route.query },
            params: {
              id: this.damageWaiverIntent.payment_intent_id,
              isDamageProtection: true,
            },
          })
        } else {
          const purchase = await this.$store.dispatch('placeOrder', {
            reservationId: this.$route.params.id,
            itemId: this.damageWaiver.id,
            count: 1,
          })

          this.$router.push({
            name: 'store-checkout',
            query: { ...this.$route.query },
            params: {
              id: purchase.payment_intent.payment_intent_id,
              isDamageProtection: true,
            },
          })
        }
        this.logAction({
          eventType: HOTJAR_EVENTS.COMPOSE_ORDER_SUCCESSFUL,
          storeItemId: this.damageWaiver.id,
        })
      }
    },
  },
}
</script>

<style scoped>
.reservation-info-wrapper {
  /*background-color: #9898981f;*/
  position: absolute;
  height: 100%;
}
.gallery-container {
  min-height: 300px;
}
.map-wrapper {
  position: relative;
}
.back-button {
  position: absolute;
  left: 10px;
}
.border-img {
  border: 3px solid white;
}
.flip-horizontal {
  transform: scaleX(-1);
}
.v-expansion-panel >>> .v-expansion-panel-content__wrap {
  padding: 0;
}
</style>
